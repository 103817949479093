import { analytics, ANALYTICS_ACTIONS, Spacing } from '@mid-react-common/common';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { AddinList, AddinsVersionInfo } from 'mid-types';
import { installersTestIds } from 'tests/helpers/dataTestIds';
import text from '../../../global/text.json';
import { ButtonsWrapper, InformationWrapper } from '../Installers.styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface DownloadSectionProps {
  title: string;
  information: string;
  addinUrl: AddinsVersionInfo;
  productPageUrl: string;
  productName: AddinList;
}

const DownloadSection: React.FC<DownloadSectionProps> = ({ title, information, addinUrl, productPageUrl, productName }) => {
  const handleAddinDownloadClick = (year: string, version: string) => {
    // Segment event
    analytics.track(ANALYTICS_ACTIONS.MIDW.clickAddinDownload, {
      addinHostApplicationVersion: year,
      addinVersion: version,
      productName,
    });
  };

  return (
    <InformationWrapper>
      <Typography variant="h1" fontWeight="bolder" data-testid={installersTestIds.productTitle}>
        {title}
      </Typography>
      <Spacing />
      <Typography data-testid={installersTestIds.downloadInformation}>{information}</Typography>
      <Spacing />
      <ButtonsWrapper>
        {Object.entries(addinUrl).map(([year, value]) => (
          <Button
            key={year}
            variant="outlined"
            component="a"
            href={value.fileURL}
            data-testid={`${year}-${installersTestIds.downloadButton}`}
            onClick={() => handleAddinDownloadClick(year, value.version)}
          >
            {text.installers.downloadButton} {year}
          </Button>
        ))}
        <Button
          color="inherit"
          startIcon={<ArrowCircleRightOutlinedIcon />}
          component="a"
          href={productPageUrl}
          target="_blank"
          data-testid={installersTestIds.productPageLink}
        >
          {text.installers.videosAndMore}
        </Button>
      </ButtonsWrapper>
    </InformationWrapper>
  );
};

export default DownloadSection;
