import {
  AppLoadingProgress,
  ConfirmationModal,
  ErrorBoundary,
  ErrorFallback,
  MIDEmptyState,
  MidWebAppMoniker,
  ModalContext,
  NotificationsProvider,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  lightTheme,
  useModalStore,
  useNotificationStore,
} from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import GatekeeperContext from 'context/GatekeeperStore/Gatekeeper.context';
import { useGatekeeperStore } from 'context/GatekeeperStore/gatekeeperStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InversifyTypes, inversifyContainer, registerAuthHandler, registerApiBaseURL, registerEnv } from 'mid-api-services';
import { ENVIRONMENT, Environment } from 'mid-types';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Outlet, matchPath, useMatch } from 'react-router-dom';
import { mainApp } from 'tests/helpers/dataTestIds';
import './App.css';
import { EmptyStateWrapper, NavHorizontal, TabsWrapper } from './App.style';
import { QueryClientProvider } from '@mid-react-common/common';
import HeaderAppBar from './components/Header/HeaderAppBar/HeaderAppBar';
import AccessControlContext from './context/AccessControlStore/AccessControl.context';
import { useAccessControlStore } from './context/AccessControlStore/accessControlStore';
import AccountProjectContext from './context/AccountProjectStore/AccountProject.context';
import { useAccountProjectsStore } from './context/AccountProjectStore/accountProjectStore';
import ModelSelectionContext from './context/ModelSelectionStore/ModelSelection.context';
import { useModelSelectionStore } from './context/ModelSelectionStore/modelSelectionStore';
import ProductContext from './context/ProductStore/Product.context';
import { useProductStore } from './context/ProductStore/productStore';
import UserContext from './context/UserStore/User.context';
import { useUserStore } from './context/UserStore/userStore';
import { useDefaultNavigation } from './global/hooks/hooks';
import text from './global/text.json';
import { nonProjectRoutes, routes } from './routes/routes';
import { routes as legacyRoutes } from './routes/legacy/legacyRoutes';

const handleResetAppState = (): void => {
  // Reloads the page & takes you back to the root URL
  window.location.href = window.location.origin;
};

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  moniker: MidWebAppMoniker,
  appVersion: import.meta.env.VITE_MIDW_VERSION || 'Not provided',
});

const AppWithNotifications: React.FC = () => {
  const notificationStore = useNotificationStore();
  const errorBoundaryText = text.errorBoundary;

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      <ThemeProvider theme={lightTheme}>
        <NotificationsProvider store={notificationStore}>
          <App />
        </NotificationsProvider>
      </ThemeProvider>
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState} resetButtonText={errorBoundaryText.goBackToHomePage}>
      <ThemeProvider theme={lightTheme}>
        <NotificationsProvider store={notificationStore}>
          <App />
        </NotificationsProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const ContentOrEmptyState: React.FC<{ children: ReactElement; isNonProjectRoute: boolean }> = ({
  children,
  isNonProjectRoute,
}) => {
  const { projectId } = useContext(AccountProjectContext);
  const isProjectIdPresent = useMatch(routes.projectId.path + '/*');

  const shouldShowEmptyState = !projectId && !isProjectIdPresent && !isNonProjectRoute;

  if (shouldShowEmptyState) {
    return (
      <EmptyStateWrapper>
        <MIDEmptyState title={text.accountProjectSelector.selectAccountAndProject} />
      </EmptyStateWrapper>
    );
  }

  return children;
};

const currentEnv = (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;

const AppContent: React.FC = () => {
  const {
    initialized: accessControlInitialized,
    accessProps: { releasesTab: hasReleasesTabAccess },
    requestAccessData,
  } = useContext(AccessControlContext);

  const { enableRfoModal } = useFlags();

  const { userInfo } = useContext(UserContext);
  const { projectId } = useContext(AccountProjectContext);

  const isNonProjectRoute = nonProjectRoutes.some((route) => matchPath(location.pathname, route.path));

  useEffect(() => {
    if (!projectId || !userInfo?.userId) {
      return;
    }

    requestAccessData(projectId, userInfo.userId);
  }, [requestAccessData, projectId, userInfo?.userId]);

  /**
   * TODO: Clean NavHorizontal components after createRFO initiative
   * https://jira.autodesk.com/browse/TRADES-6186
   *
   */
  return (
    <ContentOrEmptyState isNonProjectRoute={isNonProjectRoute}>
      {isNonProjectRoute ? (
        <Outlet />
      ) : accessControlInitialized ? (
        <>
          <TabsWrapper>
            {enableRfoModal ? (
              <NavHorizontal to={routes.outputs.path} data-testid={mainApp.outputsNav}>
                {text.mainNavs.outputs}
              </NavHorizontal>
            ) : (
              <>
                <NavHorizontal to={legacyRoutes.products.path} data-testid={mainApp.legacyProductsNav}>
                  {text.mainNavs.products}
                </NavHorizontal>
                <NavHorizontal to={legacyRoutes.outputs.path} data-testid={mainApp.outputsNav}>
                  {text.mainNavs.outputs}
                </NavHorizontal>
              </>
            )}
            {hasReleasesTabAccess && (
              <NavHorizontal to={routes.releases.path} data-testid={mainApp.releasesNav}>
                {text.mainNavs.releases}
              </NavHorizontal>
            )}
          </TabsWrapper>
          <Outlet />
        </>
      ) : (
        <AppLoadingProgress />
      )}
    </ContentOrEmptyState>
  );
};

const App: React.FC = () => {
  const gatekeeperStore = useGatekeeperStore();
  const { userIsLoggedIn, getFreshToken } = gatekeeperStore;
  const userStore = useUserStore();
  const modalStore = useModalStore();
  const accountProjectsStore = useAccountProjectsStore();
  const modelSelectionStore = useModelSelectionStore();
  const productStore = useProductStore();
  const accessControlStore = useAccessControlStore();

  const { enableMaintenanceMode, enableRfoModal, midWebappUnderMaintenance } = useFlags();

  const [areServicesRegistered, setServicesRegistered] = useState<boolean>(false);

  // initialize api service(s), one-time operation
  useEffect(() => {
    if (!userIsLoggedIn) {
      return;
    }

    if (!inversifyContainer.isBound(InversifyTypes.Env)) {
      registerEnv(currentEnv);
    }

    if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
      registerAuthHandler(() => getFreshToken());
    }

    if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
      const dcApiBaseUrl = ServiceConfigMap[ServiceTypes.OFFSITE_API][currentEnv];
      registerApiBaseURL(InversifyTypes.DcApiBaseURL, dcApiBaseUrl.api);
    }

    if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][currentEnv];
      registerApiBaseURL(InversifyTypes.ForgeApiBaseURL, forgeApiBaseUrl.api);
    }

    if (!inversifyContainer.isBound(InversifyTypes.AccBridgeApiBaseURL)) {
      const accBridgeBaseUrl = ServiceConfigMap[ServiceTypes.ACC_BRIDGE][currentEnv];
      registerApiBaseURL(InversifyTypes.AccBridgeApiBaseURL, accBridgeBaseUrl.api);
    }

    setServicesRegistered(true);
  }, [userIsLoggedIn, getFreshToken]);

  /**
   * TODO: Clean up default route
   * https://jira.autodesk.com/browse/TRADES-6186
   *
   */
  const defaultRoute = enableRfoModal ? routes.outputs.path : legacyRoutes.products.path;
  useDefaultNavigation(routes.projectId.id, defaultRoute);

  return (
    <GatekeeperContext.Provider value={gatekeeperStore}>
      <UserContext.Provider value={userStore}>
        {userIsLoggedIn && areServicesRegistered ? (
          <QueryClientProvider>
            <UserAnalyticsProvider webComponentMoniker={MidWebAppMoniker}>
              {enableMaintenanceMode || midWebappUnderMaintenance ? (
                <UnderMaintenance />
              ) : (
                <AccountProjectContext.Provider value={accountProjectsStore}>
                  <AccessControlContext.Provider value={accessControlStore}>
                    <CssBaseline />
                    <ProductContext.Provider value={productStore}>
                      <ModelSelectionContext.Provider value={modelSelectionStore}>
                        <ModalContext.Provider value={modalStore}>
                          <ConfirmationModal />
                          <HeaderAppBar />
                          <AppContent />
                        </ModalContext.Provider>
                      </ModelSelectionContext.Provider>
                    </ProductContext.Provider>
                  </AccessControlContext.Provider>
                </AccountProjectContext.Provider>
              )}
            </UserAnalyticsProvider>
          </QueryClientProvider>
        ) : (
          <AppLoadingProgress />
        )}
      </UserContext.Provider>
    </GatekeeperContext.Provider>
  );
};

export default AppWithNotifications;
