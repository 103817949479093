import {
  DATETIME_FORMAT,
  ExpandableTextSection,
  FlexContainer,
  ReleaseStatusWithColorBar,
  LinkButton,
  commonText,
  AccBridgeIcon,
} from '@mid-react-common/common';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { ACC_BRIDGE_STATUS, AccBridgeStatus, DCProductUIExtension } from 'mid-types';
import text from '../../../global/text.json';
import ReleaseStatusUpdate, { ReleaseStatusUpdateButtonVariant } from '../ReleseStatusUpdate/ReleaseStatusUpdate';
import {
  IconWrapper,
  ReleaseDetailsListItem,
  StaticParametersTooltipContent,
  ReleaseDetailsIconButton,
  StaticReleaseParametersLockIcon,
  ReleaseNotesInfoIcon,
  StaticParametersTooltipLink,
} from './ReleaseDetails.styles';
import ReleaseNotesEdit from '../ReleaseNotesEdit/ReleaseNotesEdit';
import { applyFormRulesToInputs } from 'mid-utils';

import Tooltip from '@mui/material/Tooltip';
import { KeyValueStackItem } from 'components/Commons/KeyValueStack/KeyValueStack';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { STATIC_CONTENT_LINK } from 'global/constants/links';

import { releasesDetailsIds } from 'tests/helpers/dataTestIds';

const releasesText = text.releases;
const numberOfDefaultVisibleParameters = 4;

const CreateReleaseDetailsFolderPath: React.FC<{
  releaseFullPath: string[] | Error;
  accBridgeStatus?: AccBridgeStatus | undefined;
}> = ({ releaseFullPath, accBridgeStatus }) => {
  const theme = useTheme();
  const iconWidth = theme.var.releaseDetailsFolderIconWidth;
  if (releaseFullPath instanceof Error) {
    return <Typography color="error">{releaseFullPath.message}</Typography>;
  }
  return (
    <ul>
      {releaseFullPath.map((folder: string, index) => (
        <ReleaseDetailsListItem key={`${folder}-${index}`}>
          <FlexContainer>
            {index > 1 && <Box width={iconWidth * (index - 1)} height={iconWidth}></Box>}
            <IconWrapper>
              {index !== 0 && <SubdirectoryArrowRightRoundedIcon color="disabled" />}
              <FolderOutlinedIcon />
            </IconWrapper>
            {folder}
            {index === releaseFullPath.length - 1 && (
              <Box marginLeft={`${theme.var.marginBase / 2}px`}>
                <AccBridgeIcon accBridgeStatus={accBridgeStatus} />
              </Box>
            )}
          </FlexContainer>
        </ReleaseDetailsListItem>
      ))}
    </ul>
  );
};

interface ReleaseInfoProps {
  currentRelease: DCProductUIExtension<ProductRelease>;
  parameterVisibility: boolean;
  releaseFullPath: string[] | Error;
  handleShowAllClick: () => void;
  productReleases: DCProductUIExtension<ProductRelease>[];
  refetchProductReleases: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<DCProductUIExtension<ProductRelease>[], Error>>;
  enableStaticContent?: boolean;
}

export const releaseInfo = ({
  currentRelease,
  parameterVisibility,
  releaseFullPath,
  handleShowAllClick,
  productReleases,
  refetchProductReleases,
  enableStaticContent,
}: ReleaseInfoProps): KeyValueStackItem[] => {
  const infoItems: KeyValueStackItem[] = [];

  const inputsWithFormRules = applyFormRulesToInputs({
    productReleaseInputs: currentRelease.inputs,
    formRules: currentRelease.rules?.formRules?.code,
  });

  if (enableStaticContent) {
    infoItems.push({
      label: text.common.publishedAs,
      value: currentRelease.isConfigurable === false ? text.common.staticRelease : text.common.configurableRelease,
    });
  }

  const shouldShowStaticContentLockTooltip =
    enableStaticContent && currentRelease.isConfigurable === false && !!inputsWithFormRules.length;
  const shouldShowStaticReleaseNoParametersMessage =
    enableStaticContent && currentRelease.isConfigurable === false && inputsWithFormRules.length === 0;

  infoItems.push({
    label: (
      <>
        {releasesText.releaseDetailsParameters}

        {shouldShowStaticContentLockTooltip && (
          <Tooltip
            placement="right"
            title={
              <StaticParametersTooltipContent>
                {text.releases.staticReleaseParametersTooltipText}
                <StaticParametersTooltipLink href={STATIC_CONTENT_LINK}>
                  {text.releases.staticReleaseParametersTooltipLinkText}
                </StaticParametersTooltipLink>
              </StaticParametersTooltipContent>
            }
            arrow
          >
            <ReleaseDetailsIconButton
              disableRipple
              disableFocusRipple
              data-testid={releasesDetailsIds.staticContentLockTooltipButton}
            >
              <StaticReleaseParametersLockIcon />
            </ReleaseDetailsIconButton>
          </Tooltip>
        )}
      </>
    ),
    value: (
      <ul>
        {shouldShowStaticReleaseNoParametersMessage && (
          <Typography display="inline" color="text.secondary">
            {text.common.staticReleaseNoParameters}
          </Typography>
        )}

        {inputsWithFormRules.map((input, index) => (
          <Box
            key={input.name}
            display={index > numberOfDefaultVisibleParameters - 1 && !parameterVisibility ? 'none' : 'block'}
          >
            <ReleaseDetailsListItem>
              {input.label ? (
                <>
                  <Typography display="inline">{input.label}</Typography>{' '}
                  <Typography display="inline" color="text.secondary">
                    {input.applicable === false ? commonText.notApplicableLabel : input.value.toString()}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography display="inline">
                    {input.name} {input.unit ? <>({input.unit})</> : null}
                  </Typography>{' '}
                  <Typography display="inline" color="text.secondary">
                    {input.applicable === false ? commonText.notApplicableLabel : input.value.toString()}
                  </Typography>
                </>
              )}
            </ReleaseDetailsListItem>
          </Box>
        ))}
        {currentRelease.inputs.length > numberOfDefaultVisibleParameters && (
          <ReleaseDetailsListItem>
            <LinkButton topMargin onClick={handleShowAllClick}>
              {parameterVisibility ? releasesText.showLess : releasesText.showAll}
            </LinkButton>
          </ReleaseDetailsListItem>
        )}
      </ul>
    ),
  });

  infoItems.push({
    label: releasesText.releasesTableColumnStatus,
    value: (
      <FlexContainer>
        <ReleaseStatusWithColorBar status={currentRelease.status} />
        <ReleaseStatusUpdate
          productReleases={productReleases}
          releaseNumber={currentRelease.release}
          refetchProductReleases={refetchProductReleases}
          buttonText={releasesText.updateReleaseStatus}
          buttonVariant={ReleaseStatusUpdateButtonVariant.linkButton}
        />
      </FlexContainer>
    ),
  });

  infoItems.push({
    label: (
      <>
        {releasesText.releaseDetailsNotes}
        <Tooltip placement="top" title={commonText.releaseNotes.description} arrow>
          <ReleaseDetailsIconButton disableRipple disableFocusRipple>
            <ReleaseNotesInfoIcon />
          </ReleaseDetailsIconButton>
        </Tooltip>
      </>
    ),
    value: (
      <>
        <ExpandableTextSection
          className={!currentRelease.notes ? 'mid-status-secondary' : ''}
          content={currentRelease.notes || commonText.releaseNotes.notesUnspecified}
          actionsBar={
            currentRelease.accBridgeStatus !== ACC_BRIDGE_STATUS.INCOMING ? (
              <FlexContainer>
                <ReleaseNotesEdit
                  currentRelease={currentRelease}
                  releasesCount={productReleases.length}
                  refetchProductReleases={refetchProductReleases}
                />
              </FlexContainer>
            ) : undefined
          }
        />
      </>
    ),
  });

  infoItems.push({
    label: releasesText.releasesTableColumnReleasedOn,
    value: format(Date.parse(currentRelease.createdAt), DATETIME_FORMAT),
  });

  infoItems.push({
    label: releasesText.releaseDetailsFolderPath,
    value: (
      <CreateReleaseDetailsFolderPath releaseFullPath={releaseFullPath} accBridgeStatus={currentRelease.accBridgeStatus} />
    ),
  });

  return infoItems;
};

export const getPublishLocationPathFromFolderPath = async (
  targetProjectId: string,
  folderUrn?: string,
): Promise<string[]> => {
  if (!folderUrn) {
    return [];
  }
  const folderPath = await getForgeApiServiceInstance().getFolderPathList(targetProjectId, folderUrn);
  return folderPath;
};
