import TableBody from '@mui/material/TableBody';
import { RFOTableCellWrapper, RFOTableRowWrapper, TypographyWithDefaultCursor } from '../CheckoutStep.styles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { TableRowData } from '../utils';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { removeExtension, removePath } from 'components/LegacyOutputsPage/LegacySettingsPanel/outputSettings.utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
const checkoutStepTestIds = rfoModalTestIds.checkoutStep;

interface OutputsTableBodyProps {
  tableData: TableRowData[];
}

const FileTypeTooltip: React.FC<{ outputType: TableRowData }> = ({ outputType }) => {
  const theme = useTheme();
  return (
    <>
      {outputType.outputs && (
        <Tooltip
          data-testid={`${outputType.label}-${checkoutStepTestIds.outputTooltip}`}
          title={
            <Box padding={`${theme.var.paddingBase}px`}>
              <Typography fontWeight="bolder" gutterBottom>
                {outputType.fileTypeTitle}
              </Typography>
              <Box alignContent="baseline" marginLeft={`${theme.var.marginBase * 1.5}px`}>
                <ul>
                  {outputType.outputs.map((output, index) => (
                    <li key={`${output}-${index}`}>
                      <Typography>{removePath(removeExtension(output))}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          }
          placement="bottom-start"
          arrow
        >
          <TypographyWithDefaultCursor
            marginLeft={`${theme.var.marginBase}px`}
            width="fit-content"
            color={theme.palette.primary.main}
          >
            {outputType.outputs.length} {outputType.fileTypeText}
          </TypographyWithDefaultCursor>
        </Tooltip>
      )}
    </>
  );
};

const OutputsTableBody: React.FC<OutputsTableBodyProps> = ({ tableData }) => {
  const theme = useTheme();
  const { webappTokenCostStrikethrough } = useFlags();

  return (
    <TableBody>
      {tableData.map((outputType, index) => (
        <RFOTableRowWrapper key={`${outputType.type}-${index}`}>
          <RFOTableCellWrapper
            isLastRow={index === tableData.length - 1}
            data-testid={`${outputType.label}-${checkoutStepTestIds.fileType}`}
            align="left"
          >
            <Typography fontWeight="bold" display="inline">
              {outputType.label}
            </Typography>{' '}
            <Typography display="inline" color={theme.palette.text.secondary}>
              {outputType.fileExtension}
            </Typography>
            {outputType.outputs && (
              <Box display="flex" margin={`${theme.var.marginBase}px ${theme.var.marginBase * 1.5}px`}>
                <SvgIcon fontSize="small">{outputType.icon}</SvgIcon>
                {outputType.outputs.length > 1 ? (
                  <FileTypeTooltip outputType={outputType} />
                ) : (
                  <Typography marginLeft={`${theme.var.marginBase}px`}>
                    {removePath(removeExtension(outputType.outputs[0]))}
                  </Typography>
                )}
              </Box>
            )}
          </RFOTableCellWrapper>
          <RFOTableCellWrapper
            data-testid={`${outputType.label}-${checkoutStepTestIds.outputCount}`}
            isLastRow={index === tableData.length - 1}
            align="right"
          >
            {outputType.fileTypeQuantity}
          </RFOTableCellWrapper>
          <RFOTableCellWrapper
            data-testid={`${outputType.label}-${checkoutStepTestIds.tokenCost}`}
            isLastRow={index === tableData.length - 1}
            align="right"
          >
            <Box display="flex" justifyContent="flex-end" gap={`${theme.var.gapBase}px`}>
              {webappTokenCostStrikethrough && (
                <Typography sx={{ textDecoration: 'line-through' }}>{outputType.tokenCost}</Typography>
              )}
              <Typography>0</Typography>
            </Box>
          </RFOTableCellWrapper>
        </RFOTableRowWrapper>
      ))}
    </TableBody>
  );
};

export default OutputsTableBody;
