export const sidebarNavTestIds = {
  sidebarItemButton: 'sidebar-item-button',
  sidebarItemText: 'sidebar-item-text',
  showHideSidebar: 'show-hide-sidebar',
};

/**
 * TODO: Remove legacyProductsNav after createRFO initiative
 * https://jira.autodesk.com/browse/TRADES-6186
 *
 */
export const mainApp = {
  legacyProductsNav: 'products-nav',
  outputsNav: 'outputs-nav',
  releasesNav: 'releases-nav',
};

export const header = {
  logo: 'logo',
};

export const modelFolderContentIds = {
  modelsFolderContentSearch: 'models-folder-content-search',
};

export const modelDetails = {
  modelDetailsOpenButton: 'model-details-open-button',
  generateOutputsButton: 'generate-outputs-button',
  outputsGenerationDisabledIcon: 'outputs-generation-disabled-icon',
  outputsGenerationDisabledInfoTooltipTitle: 'outputs-generation-disabled-info-tooltip-title',
  outputsGenerationDisabledInfoTooltipBody: 'outputs-generation-disabled-info-tooltip-body',
  outputsGenerationDisabledInfoTooltipFooter: 'outputs-generation-disabled-info-tooltip-footer',
  outputsGenerationNoRfaIcon: 'outputs-generation-no-rfa-icon',
};

export const outputsSettingsIds = {
  assignButton: 'output-settings-assign-button',
  selectProductPrompt: 'select-product-prompt',
  selectAllOutputs: 'select-all-outputs',
  generateButton: 'generate-button',
  outputFolderButton: 'output-folder-button',
};

export const outputsReviewPanel = {
  statusTable: 'outputs-review-status-table',
  allFilter: 'outputs-review-all-filter',
  successFilter: 'outputs-review-success-filter',
  failedFilter: 'outputs-review-failed-filter',
  downloadButton: 'outputs-review-download-button',
  openOutputLocationButton: 'outputs-review-open-output-location-button',
  circularProgress: 'outputs-review-circular-progress',
  downloadLogsButton: 'outputs-review-download-logs-button',
  logData: 'outputs-review-log-data',
  logDataClose: 'outputs-review-log-close',
  logDownload: 'outputs-review-log-download',
  instancesReviewLink: 'instances-review-link',
  instancesReviewElementId: 'instances-review-element-id',
  okButton: 'instances-review-ok-button',
  elementIds: 'elementIds',
  instancesResultDialog: 'instances-result-dialog',
  permissionErrorIcon: 'outputs-review-permission-error-icon',
};

export const InstancesPanelIds = {
  expandTableButton: 'instance-panel-expand-table-button',
};

export const SelectionFilterIds = {
  selectionFilterToggleAll: 'selection-filter-toggle-all',
  selectionFilterToggleSelected: 'selection-filter-toggle-selected',
  selectionFilterToggleNotSelected: 'selection-filter-toggle-not-selected',
};

export const releasesPageIds = {
  productsSearch: 'products-search',
  productsList: 'products-list',
  buttonShowHideDetails: 'button-show-hide-details',
  buttonViewIn3d: 'button-view-in-3d',
  buttonViewIn3dClose: 'button-view-in-3d-close',
};

export const releasesDetailsIds = {
  releaseDetailsThumbnail: 'release-details-thumbnail',
  releaseDetailsSummary: 'release-details-summary',
  releaseDetailsCloseButton: 'release-details-close-button',
  staticContentLockTooltipButton: 'static-content-lock-tooltip-button',
};

/**
 * TODO: needs to remove these test ids after RFO initiative
 * These ids are only used in legacy components
 * https://jira.autodesk.com/browse/TRADES-6186
 */
export const UploadLocationIds = {
  breadcrumpsContent: 'breadcrumps-content',
  selectButton: 'select-button',
  addSubFolderButton: 'add-subfolder-button',
  tooltipIconButton: 'tooltip-icon-button',
};

export const customPagination = {
  nextButton: 'next-button',
  previousButton: 'previous-button',
  firstPageButton: 'first-page-button',
  lastPageButton: 'last-page-button',
};

export const detailsSidebarIds = {
  detailsSidebarWrapper: 'details-sidebar-wrapper',
  detailsSidebarHeader: 'details-sidebar-header',
  detailsSidebarContent: 'details-sidebar-content',
};

export const releaseUpdateIds = {
  releaseUpdateUpdateButton: 'release-update-update-button',
  releaseUpdateCancelButton: 'release-update-cancel-button',
  releaseUpdateWarning: 'release-update-warning',
  releaseUpdateButton: 'release-update-button',
  releaseUpdateCurrentStatus: 'release-update-current-status',
  releaseUpdateOptions: 'release-update-options',
  releaseUpdateSelectNewDefault: 'release-update-select-new-default',
};

export const releaseNotesEditIds = {
  releaseNotesEditCancelButton: 'release-notes-edit-cancel-button',
  releaseNotesSaveButton: 'release-notes-save-button',
  releaseNotesEditButton: 'release-notes-edit-button',
};

export const accountProjectSelectorTestIds = {
  projectList: 'project-list',
  projectSelectorBanner: 'project-selector-banner',
  searchInput: 'account-project-selector-search-input',
  cancelSearchButton: 'account-project-selector-cancel-search-button',
};

export const licensingTestIds = {
  sectionsLegend: 'sections-legend',
  buttonPrint: 'button-print',
  privacyId: 'privacy',
  trademarksId: 'trademarks',
  patentsId: 'patents',
  componentsId: 'components',
  introductionSection: 'introduction-section',
  privacySection: 'privacy-section',
  trademarksSection: 'trademarks-section',
  patentsSection: 'patents-section',
  componentsSection: 'components-section',
  generalTermsPage: 'general-terms-page',
  privacyStatementPage: 'privacy-statement-page',
  trademarksPage: 'trademarks-page',
  patentsPage: 'patents-page',
  cloudPlatformComponentsPage: 'cloud-platform-components-page',
  desktopPlatformComponentsPage: 'desktop-platform-components-page',
};

export const installersTestIds = {
  productTitle: 'product-title',
  downloadInformation: 'download-information',
  downloadButton: 'download-button',
  productPageLink: 'product-page-link',
  largeThumbnail: 'large-thumbnail',
  smallThumbnailWrapper: 'small-thumbnail-wrapper',
  productCampaignLink: 'product-campaign-link',
};

export const foldersTreeTestIds = {
  newFolderInput: 'new-folder-input',
  confirmNewFolderButton: 'confirm-new-folder-button',
  subFolderCreationMainContainer: 'sub-folder-creation-main-container',
  subFolderCreationLoadingSpinner: 'sub-folder-creation-loading-spinner',
  subFolderCreationErrorIcon: 'sub-folder-creation-error-icon',
  folderIcon: 'folder-icon',
  folderOpenIcon: 'folder-open-icon',
  filledFolderOpenIcon: 'filled-folder-open-icon',
  filledFolderIcon: 'filled-folder-icon',
  lockedFolderIcon: 'locked-folder-icon',
  lockedFolderOpenIcon: 'locked-folder-open-icon',
};

export const uploadLocationProjectSelectorTestIds = {
  projectListDropdown: 'upload-location-project-selector-dropdown',
  loading: 'upload-location-project-selector-loading',
  bim360ProjectIconType: 'upload-location-project-selector-bim360-icon-type',
  accProjectIconType: 'upload-location-project-selector-acc-icon-type',
  fusionProjectIconType: 'upload-location-project-selector-fusion-icon-type',
};

export const rfoModalTestIds = {
  checkoutButton: 'rfo-checkout-button',
  checkoutStepScreen: 'rfo-checkout-step-screen',
  fileTypesStepScreen: 'rfo-file-types-step-screen',
  uploadLocationStepScreen: 'rfo-upload-location-step-screen',
  fileTypesStep: {
    model3DSection: 'model-3d-section',
    drawing2DSection: 'drawing-2d-section',
    bomSection: 'bom-section',
    neutralFormatSection: 'neutral-format-section',
    drawing2dDropdowns: 'drawing2d-dropdowns',
    neutralFormatDropdowns: 'neutral-format-dropdowns',
    bomDropdowns: 'bom-dropdowns',
    chipRemoveButton: 'chip-remove-button',
    allDrawingsMenuItem: 'all-drawings-menu-item',
    drawingOutputMenuItem: 'drawing-menu-item',
    drawingOutputChip: 'drawing-output-chip',
    allNeutralFormatMenuItem: 'all-neutral-format-menu-item',
    neutralFormatOutputMenuItem: 'neutral-format-menu-item',
    neutralFormatOutputChip: 'neutral-format-output-chip',
    allBomMenuItem: 'all-bom-menu-item',
    bomOutputMenuItem: 'bom-output-menu-item',
    bomOutputChip: 'bom-output-chip',
    uploadLocationButton: 'upload-location-button',
  },
  uploadLocationStep: {
    addSubFolderButton: 'add-subfolder-button',
    permissionTooltipIconButton: 'permission-tooltip-icon-button',
    bridgeFolderIconButton: 'bridge-folder-tooltip-icon-button',
    fileTypesButton: 'file-types-button',
  },
  checkoutStep: {
    uploadLocationAccountName: 'upload-location-account-name',
    uploadLocationProjectName: 'upload-location-project-name',
    uploadLocationFolderName: 'upload-location-folder-name',
    showHideBreadcrumbs: 'show-hide-breadcrumbs',
    folderIcon: 'folder-icon',
    lockedFolderIcon: 'locked-folder-icon',
    folderPathBreadcrumbs: 'folder-path-breadcrumbs',
    fileTypesStepChangeButton: 'file-types-step-change-button',
    uploadLocationStepChangeButton: 'upload-location-step-change-button',
    uploadLocationStepBackButton: 'upload-location-step-back-button',
    fileType: 'file-type',
    outputCount: 'output-count',
    tokenCost: 'token-cost',
    outputTooltip: 'output-tooltip',
    canGenerateOutputsErrorTooltip: 'can-generate-outputs-error-tooltip',
    submitRequest: 'checkout-submit-request',
  },
  stepper: {
    fileTypesStep: 'stepper-file-types-step',
    uploadLocationStep: 'stepper-upload-location-step',
    checkoutStep: 'stepper-checkout-step',
    completedIcon: 'stepper-completed-icon',
    errorIcon: 'stepper-error-icon',
  },
};
